import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Page, PayerDto, PayerEntity, PayerResource } from '@portal/shared/utils';

export interface GetPayersParams {
    searchText?: string;
    page?: number;
    size?: number;
}

@Injectable()
export class PayerResourceController {
    private readonly resource: PayerResource = inject(PayerResource);

    createPayer(payer: PayerDto): Observable<PayerEntity> {
        return this.resource.createPayer(payer);
    }

    getPayers(params: GetPayersParams): Observable<Page<PayerEntity>> {
        return this.resource.getPayers(params);
    }

    getPayer(payerId: number): Observable<PayerEntity> {
        return this.resource.getPayer(payerId);
    }

    updatePayer(payerId: number, payer: PayerDto): Observable<PayerEntity> {
        return this.resource.updatePayer(payerId, payer);
    }

    deletePayer(payerId: number): Observable<void> {
        return this.resource.deletePayer(payerId);
    }

    setDefaultPayer(payerId: number): Observable<PayerEntity> {
        return this.resource.setDefaultPayer(payerId);
    }

    setCaseType(payerId: number, caseTypeId: number): Observable<PayerEntity> {
        return this.resource.setCaseType(payerId, caseTypeId);
    }
}
