import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { CountryDto, CountryItemEntity, CurrencyDto } from '@portal/shared/utils';

@Injectable()
export class CountriesResource {
    private httpClient = inject(HttpClient);

    getCountries() {
        return this.httpClient.get<CountryItemEntity[]>('accounting/v1/libraries/countries');
    }

    getCountryByCode(countryCode: string) {
        return this.httpClient.get<CountryDto>(`accounting/v1/libraries/countries/${countryCode}`);
    }

    getCurrencies() {
        return this.httpClient.get<CurrencyDto[]>('accounting/v1/libraries/currencies');
    }
}
