import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Direction, Page, SupplierDto, SupplierEntity, SupplierResource } from '@portal/shared/utils';

@Injectable()
export class SuppliersResourceController {
    private readonly _resource: SupplierResource = inject(SupplierResource);

    getSupplier(supplierId: number): Observable<SupplierEntity> {
        return this._resource.getSupplier(supplierId);
    }

    createSupplier(supplierDto: SupplierDto): Observable<SupplierEntity> {
        return this._resource.createSupplier(supplierDto);
    }

    getUnlinkedSuppliers() {
        return this._resource.getUnlinkedSuppliers();
    }

    getSuppliers(params?: {
        listAll?: boolean;
        page?: number;
        size?: number;
        sort?: string;
        direction?: Direction;
        searchText?: string;
    }): Observable<Page<SupplierEntity>> {
        return this._resource.getSuppliers(params);
    }

    updateSupplier(supplierId: number, supplierDto: SupplierDto): Observable<SupplierEntity> {
        return this._resource.updateSupplier(supplierId, supplierDto);
    }

    toggleSupplierStatus(supplierId: number): Observable<SupplierEntity> {
        return this._resource.toggleSupplierStatus(supplierId);
    }

    deleteSupplier(supplierId: number): Observable<void> {
        return this._resource.deleteSupplier(supplierId);
    }
}
