import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { DisciplineDto, DisciplineEntity, DisciplinesResource, Page } from '@portal/shared/utils';

@Injectable()
export class DisciplinesResourceController {
    readonly #resource: DisciplinesResource = inject(DisciplinesResource);

    getDisciplines(): Observable<Page<DisciplineEntity>> {
        return this.#resource.getDisciplines();
    }

    getDiscipline(disciplineId: number): Observable<DisciplineEntity> {
        return this.#resource.getDiscipline(disciplineId);
    }

    getDisciplinesByIds(disciplineIds: number[]): Observable<DisciplineEntity[]> {
        return forkJoin(disciplineIds.map((id) => this.getDiscipline(id)));
    }

    createDiscipline(discipline: DisciplineDto): Observable<DisciplineEntity> {
        return this.#resource.createDiscipline(discipline);
    }

    deleteDiscipline(disciplineId: number): Observable<DisciplineEntity> {
        return this.#resource.deleteDiscipline(disciplineId);
    }

    canDeleteDiscipline(disciplineId: number): Observable<void> {
        return this.#resource.canDeleteDiscipline(disciplineId);
    }

    updateDiscipline(discipline: DisciplineDto): Observable<DisciplineEntity> {
        return this.#resource.updateDiscipline(discipline);
    }
}
