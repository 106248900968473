import { HttpEvent } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    AttachementDto,
    GrandSystemAccess,
    ProviderDto,
    TeamMemberDto,
    TeamMemberEntity,
    TeamMemberFilesDto,
    TeamMemberImageDto,
    TeamMemberLicenseDto,
    TeamMemberResource,
    TeamMemberTextDto,
    UploadFileData,
} from '@portal/shared/utils';

@Injectable()
export class TeamMembersResourceController {
    private readonly _resource: TeamMemberResource = inject(TeamMemberResource);

    getTeamMembers(): Observable<TeamMemberEntity[]> {
        return this._resource.getTeamMembers();
    }

    getTeamMember(teamMemberId: number | null | undefined): Observable<TeamMemberEntity> {
        return this._resource.getTeamMember(teamMemberId);
    }

    createTeamMember(dto: TeamMemberDto): Observable<TeamMemberEntity> {
        return this._resource.createTeamMember(dto);
    }

    updateTeamMember(teamMemberId: number, dto: TeamMemberDto): Observable<TeamMemberEntity> {
        return this._resource.updateTeamMember(teamMemberId, dto);
    }

    toggleTeamMemberStatus(teamMemberId: number): Observable<TeamMemberEntity> {
        return this._resource.toggleTeamMemberStatus(teamMemberId);
    }

    deleteTeamMember(teamMemberId: number): Observable<void> {
        return this._resource.deleteTeamMember(teamMemberId);
    }

    addProfession(teamMemberId: number, profession: TeamMemberLicenseDto): Observable<TeamMemberDto> {
        return this._resource.addProfession(teamMemberId, profession);
    }

    deleteProfession(licenseId: number): Observable<void> {
        return this._resource.deleteProfession(licenseId);
    }

    editProfession(license: TeamMemberLicenseDto): Observable<TeamMemberDto> {
        return this._resource.editProfession(license);
    }

    getLicenses(teamMemberId: number): Observable<AttachementDto[]> {
        return this._resource
            .getLicenses(teamMemberId)
            .pipe(map((teamFiles) => teamFiles.map((file) => AttachementDto.fromTeamFile(file))));
    }

    uploadLicense(uploadFileData: UploadFileData): Observable<HttpEvent<TeamMemberFilesDto[]>> {
        return this._resource.uploadLicense(uploadFileData);
    }

    deleteLicense(fileId: number): Observable<AttachementDto[]> {
        return this._resource.deleteLicense(fileId).pipe(map((teamFiles) => teamFiles.map((file) => AttachementDto.fromTeamFile(file))));
    }

    downloadFile(fileId: number): Observable<Blob> {
        return this._resource.downloadFile(fileId);
    }

    viewTextFile(fileId: number): Observable<TeamMemberTextDto> {
        return this._resource.viewTextFile(fileId);
    }

    viewImageFile(fileId: number): Observable<TeamMemberImageDto> {
        return this._resource.viewImageFile(fileId);
    }

    grantSystemAccess(grandSystemAccess: GrandSystemAccess, notify?: boolean): Observable<TeamMemberEntity> {
        const params = { ...(notify ? { sendNotification: true } : {}) };
        return this._resource.grantSystemAccess(grandSystemAccess, params);
    }

    getProviders(): Observable<ProviderDto[]> {
        return this._resource.getProviders();
    }
}
