import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, Subject, switchMap, tap } from 'rxjs';

import { CountryEntity, CountryItemEntity, CurrencyDto } from '@portal/shared/utils';

import { CountriesResourceController } from '../../../+api';

interface CountriesState {
    countries: CountryItemEntity[];
    countriesLoading: boolean;

    country: CountryEntity | null;
    countryLoading: boolean;

    currencies: CurrencyDto[];
    currenciesLoading: boolean;
}

const initialState: CountriesState = {
    countries: [],
    countriesLoading: false,

    country: null,
    countryLoading: false,

    currencies: [],
    currenciesLoading: false,
};

export const CountriesStore = signalStore(
    withState(initialState),
    withMethods(() => {
        const readCountriesSuccessSource = new Subject<CountryItemEntity[]>();
        const readCountriesFailureSource = new Subject<string>();

        const readCountryByCodeSuccessSource = new Subject<CountryEntity>();
        const readCountryByCodeFailureSource = new Subject<string>();

        const readCurrenciesSuccessSource = new Subject<CurrencyDto[]>();
        const readCurrenciesFailureSource = new Subject<string>();

        return {
            _readCountriesSuccess: (countries: CountryItemEntity[]) => readCountriesSuccessSource.next(countries),
            onReadCountriesSuccess: () => readCountriesSuccessSource.asObservable(),

            _readCountriesFailure: (error: string) => readCountriesFailureSource.next(error),
            onReadCountriesFailure: () => readCountriesFailureSource.asObservable(),

            _readCountryByCodeSuccess: (country: CountryEntity) => readCountryByCodeSuccessSource.next(country),
            onReadCountryByCodeSuccess: () => readCountryByCodeSuccessSource.asObservable(),

            _readCountryByCodeFailure: (error: string) => readCountryByCodeFailureSource.next(error),
            onReadCountryByCodeFailure: () => readCountriesFailureSource.asObservable(),

            _readCurrenciesSuccess: (Currencies: CurrencyDto[]) => readCurrenciesSuccessSource.next(Currencies),
            onReadCurrenciesSuccess: () => readCurrenciesSuccessSource.asObservable(),

            _readCurrenciesFailure: (error: string) => readCurrenciesFailureSource.next(error),
            onReadCurrenciesFailure: () => readCountriesFailureSource.asObservable(),
        };
    }),
    withMethods((store, controller = inject(CountriesResourceController)) => {
        return {
            read: rxMethod<void>(
                pipe(
                    tap(() => {
                        patchState(store, { countriesLoading: true });
                    }),
                    switchMap(() =>
                        controller.getCountries().pipe(
                            tapResponse({
                                next: (countries) => {
                                    patchState(store, { countries });
                                    store._readCountriesSuccess(countries);
                                },
                                error: (e: HttpErrorResponse) => {
                                    store._readCountriesFailure(e.message ?? null);
                                },
                                finalize: () => {
                                    patchState(store, { countriesLoading: false });
                                },
                            })
                        )
                    )
                )
            ),
            readByCode: rxMethod<string>(
                pipe(
                    tap(() => {
                        patchState(store, { countryLoading: true });
                    }),
                    switchMap((code) => {
                        return controller.getCountryByCode(code).pipe(
                            tapResponse({
                                next: (country) => {
                                    const countryWithCountryCode: CountryEntity = { ...country, countryCode: code };

                                    patchState(store, { country: countryWithCountryCode });
                                    store._readCountryByCodeSuccess(countryWithCountryCode);
                                },
                                error: (e: HttpErrorResponse) => {
                                    store._readCountryByCodeFailure(e.message ?? null);
                                },
                                finalize: () => {
                                    patchState(store, { countryLoading: false });
                                },
                            })
                        );
                    })
                )
            ),

            readCurrencies: rxMethod<void>(
                pipe(
                    tap(() => {
                        patchState(store, { currenciesLoading: true });
                    }),
                    switchMap(() =>
                        controller.getCurrencies().pipe(
                            tapResponse({
                                next: (currencies) => {
                                    patchState(store, { currencies });
                                    store._readCurrenciesSuccess(currencies);
                                },
                                error: (e: HttpErrorResponse) => {
                                    store._readCurrenciesFailure(e.message ?? null);
                                },
                                finalize: () => {
                                    patchState(store, { currenciesLoading: false });
                                },
                            })
                        )
                    )
                )
            ),
        };
    })
);
