import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ChargeDto, ChargeEntity, ChargeResource, Page } from '@portal/shared/utils';

@Injectable()
export class ChargeResourceController {
    private readonly resource: ChargeResource = inject(ChargeResource);

    createCharge(charge: ChargeDto): Observable<ChargeEntity> {
        return this.resource.createCharge(charge);
    }

    createMultipleCharge(charges: ChargeDto[]): Observable<ChargeEntity> {
        return this.resource.createMultipleCharge(charges);
    }

    getChargesByCaseFile(caseFileId: number, params?: { page?: number; size?: number }): Observable<Page<ChargeEntity>> {
        return this.resource.getChargesByCaseFile(caseFileId, params);
    }

    getChargesBySource(sourceId: number, caseFileId: number): Observable<ChargeEntity[]> {
        return this.resource.getChargesBySource(sourceId, caseFileId);
    }

    deleteChargesBySource(sourceId: number, caseFileId: number): Observable<void> {
        return this.resource.deleteChargesBySource(sourceId, caseFileId);
    }

    getUnbilledCaseFileCharges(caseFileId: number): Observable<ChargeEntity[]> {
        return this.resource.getUnbilledCaseFileCharges(caseFileId);
    }

    getCharge(chargeId: number): Observable<ChargeEntity> {
        return this.resource.getCharge(chargeId);
    }

    updateCharge(charge: ChargeDto): Observable<ChargeEntity> {
        return this.resource.updateCharge(charge);
    }

    deleteCharge(chargeId: number): Observable<void> {
        return this.resource.deleteCharge(chargeId);
    }
}
