import { HttpEvent } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ContactDto,
    ContactEntity,
    ContactResource,
    ContactType,
    FileImportResultEntity,
    GetContactsParams,
    Page,
} from '@portal/shared/utils';

@Injectable()
export class ContactResourceController {
    readonly #resource: ContactResource = inject(ContactResource);

    getContacts(params?: GetContactsParams): Observable<Page<ContactEntity>> {
        return this.#resource.getContacts(params);
    }

    getContact(id: number): Observable<ContactEntity> {
        return this.#resource.getContact(id);
    }

    getContactsByType(params?: {
        searchText?: string;
        contactType: ContactType;
        page: number;
        size: number;
        isOrganization?: boolean;
    }): Observable<Page<ContactEntity>> {
        return this.#resource.getContactsByType(params);
    }

    getContactsByGroup(groupId: number, params?: GetContactsParams): Observable<Page<ContactEntity>> {
        return this.#resource.getContactsByGroup(groupId, params);
    }

    createContact(contactDto: ContactDto): Observable<ContactEntity> {
        return this.#resource.createContact(contactDto);
    }

    deleteContact(contactId: number): Observable<void> {
        return this.#resource.deleteContact(contactId);
    }

    updateContact(contact: ContactDto): Observable<ContactEntity> {
        return this.#resource.updateContact(contact.contactId!, contact);
    }

    addContactGroup(contactId: number, groupId: number): Observable<ContactEntity> {
        return this.#resource.addContactGroup(contactId, groupId);
    }

    deleteContactGroup(contactId: number, groupId: number): Observable<ContactEntity> {
        return this.#resource.deleteContactGroup(contactId, groupId);
    }

    validateFileImport(file: File): Observable<HttpEvent<FileImportResultEntity>> {
        return this.#resource.validateFileImport(file);
    }

    contactsImport(data: { groups: number[] }): Observable<void> {
        return this.#resource.contactsImport(data);
    }
}
