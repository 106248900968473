import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStoreFeature, type, withMethods, withState } from '@ngrx/signals';
import { entityConfig, removeAllEntities, setAllEntities, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Observable, pipe, Subject, switchMap, tap } from 'rxjs';

import { LocationEntity, Page } from '@portal/shared/utils';

import { LocationsResourceController } from '../../+api';

interface State {
    locationsLoading: boolean;
    locationsLoaded: boolean;
}

export function withLocationsStoreFeature() {
    const locationsConfig = entityConfig({
        entity: type<LocationEntity>(),
        collection: 'locations',
        selectId: (model: LocationEntity) => model.locationId,
    });

    const initialState: State = {
        locationsLoading: false,
        locationsLoaded: false,
    };

    return signalStoreFeature(
        withState<State>(initialState),
        withEntities(locationsConfig),
        withMethods((store, loadSuccessSource = new Subject<LocationEntity[]>(), loadFailedSource = new Subject<void>()) => ({
            _loadLocationsAction: (): void => {
                patchState(store, { locationsLoading: true });
            },
            _loadLocationsSuccessAction: (data: LocationEntity[]): void => {
                patchState(store, setAllEntities(data, locationsConfig), { locationsLoaded: true, locationsLoading: false });
                loadSuccessSource.next(data);
            },
            onLoadLocationsSuccess: (): Observable<LocationEntity[]> => loadSuccessSource.asObservable(),
            _loadLocationsFailedAction: (data: string): void => {
                patchState(store, removeAllEntities(locationsConfig), { locationsLoaded: true, locationsLoading: false });
                loadFailedSource.next();
            },
            onLoadEntitiesFailed: (): Observable<void> => loadFailedSource.asObservable(),
        })),

        withMethods((store, locationController = inject(LocationsResourceController)) => ({
            _loadLocations: rxMethod<void>(
                pipe(
                    tap(() => store._loadLocationsAction()),
                    switchMap(() =>
                        locationController.getLocations().pipe(
                            tapResponse({
                                error: () => store._loadLocationsFailedAction('Error loading locations'),
                                next: (data: Page<LocationEntity>) => store._loadLocationsSuccessAction(data.content),
                            })
                        )
                    )
                )
            ),
        })),

        withMethods((store) => ({
            loadLocations: () => {
                store._loadLocations();
            },
        }))
    );
}
