import { Injectable, inject } from '@angular/core';

import { CountriesResource } from './countries-resource';

@Injectable()
export class CountriesResourceController {
    private resource = inject(CountriesResource);

    getCountries() {
        return this.resource.getCountries();
    }

    getCountryByCode(countryCode: string) {
        return this.resource.getCountryByCode(countryCode);
    }

    getCurrencies() {
        return this.resource.getCurrencies();
    }
}
