import { computed } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withMethods, withState } from '@ngrx/signals';

import { SafeCompare } from '@common/utils';

import { HeaderLocationModel, LocationEntity } from '@portal/shared/utils';

interface State {
    _locationId: number | null;
    _locations: LocationEntity[];
}

export function withHeaderLocationsStoreFeature() {
    const initialState: State = {
        _locationId: null,
        _locations: [],
    };

    return signalStoreFeature(
        withState<State>(initialState),
        withComputed((store) => ({
            headerLocations: computed<HeaderLocationModel[]>((locations = store._locations()) =>
                [...locations]
                    .sort((a, b) => SafeCompare.safeCompare(a, b, (a, b) => (a.headOffice ? -1 : 1)))
                    .map((location: LocationEntity) => ({
                        locationId: location.locationId,
                        contactColor: location.contactColor,
                        companyName: location.companyName,
                    }))
            ),
        })),
        withComputed((store) => ({
            // linkedSignal and writable signals is not yet supported
            selectedLocationId: computed((locationId = store._locationId(), headerLocations = store.headerLocations()) => {
                if (!headerLocations.some((location: HeaderLocationModel) => location.locationId === locationId)) {
                    return headerLocations.length > 0 ? headerLocations[0].locationId : null;
                }

                return locationId;
            }),
        })),
        withMethods((store) => ({
            setLocationId: (locationId: number): void => {
                patchState(store, { _locationId: locationId });
            },
            setLocations: (locations: LocationEntity[]): void => {
                patchState(store, { _locations: locations });
            },
        }))
    );
}
