import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { SharedFeatureActions } from './shared-feature.actions';
import { SharedFeaturePartialState } from './shared-feature.state';

@Injectable()
export class SharedFeatureFacade {
    private readonly store: Store<SharedFeaturePartialState> = inject(Store);

    initialize() {
        this.store.dispatch(SharedFeatureActions.initialize());
    }
}
