import { HttpEvent } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocationDto, LocationEntity, LocationResource, Page } from '@portal/shared/utils';

@Injectable()
export class LocationsResourceController {
    readonly #resource: LocationResource = inject(LocationResource);

    createLocation(locationDto: LocationDto): Observable<LocationEntity> {
        return this.#resource.createLocation(locationDto);
    }

    getLocations(params?: { listAll?: boolean; page?: number; size?: number }): Observable<Page<LocationEntity>> {
        return this.#resource.getLocations(params);
    }

    getLocation(locationId: number): Observable<LocationEntity> {
        return this.#resource.getLocation(locationId);
    }

    updateLocation(locationId: number, locationDto: LocationDto): Observable<LocationEntity> {
        return this.#resource.updateLocation(locationId, locationDto);
    }

    deleteLocation(locationId: number): Observable<void> {
        return this.#resource.deleteLocation(locationId);
    }

    setHeadOffice(locationId: number): Observable<LocationEntity> {
        return this.#resource.setHeadOffice(locationId);
    }

    toggleActiveStatus(locationId: number): Observable<LocationDto> {
        return this.#resource.toggleActiveStatus(locationId);
    }

    uploadLocationLogo(locationId: number, file: Blob): Observable<HttpEvent<LocationEntity>> {
        return this.#resource.updateLocationLogo(locationId, file);
    }

    deleteLocationLogo(locationId: number): Observable<LocationEntity> {
        return this.#resource.deleteLocationLogo(locationId);
    }
}
