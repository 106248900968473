import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactGroupsDto, ContactGroupsResource, ContactGroupsTotalEntity } from '@portal/shared/utils';

@Injectable()
export class ContactGroupsResourceController {
    readonly #resource: ContactGroupsResource = inject(ContactGroupsResource);

    getAllGroups(): Observable<ContactGroupsTotalEntity> {
        return this.#resource.getContactGroups();
    }

    createGroup(group: ContactGroupsDto): Observable<ContactGroupsDto> {
        return this.#resource.createContactGroup(group);
    }

    deleteGroup(groupId: number): Observable<void> {
        return this.#resource.deleteContactGroup(groupId);
    }

    renameGroup(group: ContactGroupsDto): Observable<ContactGroupsDto> {
        return this.#resource.updateContactGroup(group.id, group);
    }
}
