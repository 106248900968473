import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginDto, Page, UserDto, UserEntity, UserRowEntity, UsersResource } from '@portal/shared/utils';

@Injectable()
export class UsersResourceController {
    private readonly _resource: UsersResource = inject(UsersResource);

    getAllUsers(page: number, size: number): Observable<Page<UserRowEntity>> {
        return this._resource.getAllUsers(page, size);
    }

    getUnassignedUsers(): Observable<UserEntity[]> {
        return this._resource.getUnassignedUsers();
    }

    createUser(userDto: UserDto, notify?: boolean): Observable<UserEntity> {
        const params = { ...(notify ? { sendNotification: true } : {}) };
        return this._resource.createUser(userDto, params);
    }

    getUser(userId: number, params?: { infoFull?: boolean }): Observable<UserEntity> {
        return this._resource.getUser(userId, params);
    }

    updateUser(userId: number, userDto: UserDto): Observable<UserEntity> {
        return this._resource.updateUser(userId, userDto);
    }

    deleteUser(userId: number): Observable<void> {
        return this._resource.deleteUser(userId);
    }

    toggleUserActive(userId: number): Observable<UserEntity> {
        return this._resource.toggleUserActive(userId);
    }

    toggleLocation(userId: number, locationId: number): Observable<void> {
        return this._resource.toggleLocation(userId, locationId);
    }

    changeRole(userId: number, userDto: UserDto): Observable<UserEntity> {
        return this._resource.changeRole(userId, userDto);
    }

    resetPassword(userId: number, userDto: UserDto, notify?: boolean): Observable<UserEntity> {
        const params = { ...(notify ? { sendNotification: true } : {}) };
        return this._resource.resetPassword(userId, userDto, params);
    }

    recordAuditLinkedTeamMember(userId: number): Observable<void> {
        return this._resource.recordAuditLinkedTeamMember(userId);
    }

    changePassword(loginDto: LoginDto): Observable<UserEntity> {
        return this._resource.changePassword(loginDto);
    }
}
